
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'; // Import React Router
import PrivacyPolicy from './pages/PrivacyPolicy'; 
import Home from './pages/Home';


function App() {
  return (
    <BrowserRouter basename='/public'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
