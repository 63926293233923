import React from 'react'
import { Navbar, Container, Button, Nav } from 'react-bootstrap'
import img from '../assets/logo1.png'
import { Link } from 'react-router-dom'
const Header = () => {
  return (
    <>
   <title>Below The Market</title>
   <Navbar expand="lg" className="head">
      <Container>
        <Navbar.Brand href="#" className='logo'>
            <Link to='/'> <img alt="card img" className="rounded-t float-right " src={img} /></Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
          </Nav>
          <Button className='btn bg-primary text-dark'>Go to website</Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  )
}

export default Header