import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Container } from 'react-bootstrap';
import Privacy from '../components/PrivacyPolicy';

function PrivacyPolicy() {
  return (
    <div className='p-0 m-0'>
      <Header />
      <Container>
        <Privacy />
      </Container>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;