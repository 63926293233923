import React from 'react'
import { Col, Container, Row , Form, Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'

const SignUp = () => {
  return (
    <>
    <Container className='py-5 form'>
        <Row className='sign-up justify-content-center'>
            <Col lg='5' md={9} sm={11}>
               <div className='text-center'>
               <h2>Sign up <strong className='fw-bold text-primary'> NOW</strong></h2>
                <p>Be among the first to experience Below The Market</p>
               </div>
                <Form className='ms-3'>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="email" placeholder="Enter your email" className='mb-1' required />
                        <Form.Text className="text-muted ms-1">
                           <span>We care about your data in our <Link to="/privacy-policy" class="text-dark">privacy policy</Link>.</span> 
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Control type="tel" placeholder="Phone Number" required />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Control type="fullname" placeholder="Full Name" required />
                    </Form.Group>

                    <Button variant="" type="submit" className=' bg-primary text-dark'>
                        Sign Up
                    </Button>
                </Form>
            </Col>

        </Row>
    </Container>
    </>
  )
}

export default SignUp