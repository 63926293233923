import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import img from '../assets/logo1.png'
import heroImg from '../assets/abc.png'
import perImg from '../assets/per.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube ,faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Hero = () => {
  return (
    <>
    <hr/>
    <Container className='bg-light fluid m-0 pb-5'>
        <Row className='justify-content-center align-items-center bg-light mt-5'>
            <Col xl={12} lg={12} md={9} sm={12} className="text-center top-img mb-3" >
                <img alt="card img" className="img-fluid mb-4" src={img} />
            </Col>

            <Col xl={5} lg={7}  md={9} sm={12}className="mb-5">
                <h1>Where deals <span className='text-primary'> are made</span></h1>
                <div className='hero-text'>
                    <p className=''>
                        The new property platform where all listings are 
                        <span className='h1 number-and-img' style={{color: '#00004D'}}> 10 </span> 
                        <img alt="card img" className="number-and-img" src={perImg} /> or more below the market value.
                    </p>
                </div>


                <div className='mt-5 social'>
                    <h3 className='text-secondary'>Socials:</h3>
                    <div className='d-flex justify-content-between w-75 mt-4'>
                        <a href='https://www.facebook.com/belowthemarket'><FontAwesomeIcon icon={faFacebookF}  className="icon bg-primary fs-2 rounded-circle text-white" /></a>
                        <a href='https://www.instagram.com/belowthemarket'><FontAwesomeIcon icon={faInstagram} className="icon bg-primary fs-2 rounded-circle text-white" /></a>
                        <a href='https://www.youtube.com/@BelowTheMarket'><FontAwesomeIcon icon={faYoutube} className="icon bg-primary fs-2 rounded-circle text-white" /></a>
                        <a href='https://www.linkedin.com/company/belowthemarket/'><FontAwesomeIcon icon={faLinkedinIn}  className="icon bg-primary fs-2 rounded-circle text-white" /></a>
                    </div>
                </div>
            </Col>

            <Col xl={6} lg={5} md={6}>
                <img alt="card img" className="img-fluid mb-4" src={heroImg} />
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default Hero