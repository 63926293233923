import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const FormSection = () => {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const reserveUsername = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('/api/create-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: fullName,
          email: email,
          phone_number: phoneNumber,
          username: username
        })
      });
      const data = await response.json();

      if (!response.ok) {
        if(data.message === 'Username already exists'){
            alert('Username already exists');
        }
        else if(data.message === 'Email already exists')
        {
            alert('Email already exists');
        }
        throw new Error('Failed to create user');
      }      
    alert('Username reserved successfully');
    setSubmitError(null);
    setFormSubmitted(true);

      
      
    } catch (error) {
      console.error('Error creating user:', error);
      setSubmitError(error.message);
    }
  };

  const handleUsernameChange = async(event) => {
    const newUsername = event.target.value;
    setUsername(newUsername);
    try {
        const response = await fetch(`/api/check-username?username=${newUsername}`);
        const data = await response.json();
        if(data.exists) {
            setUsernameError(true);
        }
        else{
            setUsernameError(false);
        }
    } catch (error) {
      console.error('Error checking username:', error);
    }
  };

  const handleEmailChange = async(event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    try {
        const response = await fetch(`/api/check-email?email=${newEmail}`);
        const data = await response.json();
        if(data.exists) {
            setEmailError(true);
        }
        else{
            setEmailError(false);
        }
    } catch (error) {
      console.error('Error checking username:', error);
    }
  };


  return (
    <Container className='py-5 form'>
      <Row className="justify-content-center">
        <Col lg={9} className="text-center">
          <h2>Complete the form below to reserve your username</h2>
          <p>Be among the first to experience Below The Market</p>
        </Col>

        <Col lg={5} md={8} sm={10} className="mt-4 ps-4">
          <Form className='ms-2' onSubmit={reserveUsername}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="email"
                placeholder="Enter your email"
                className='mb-1'
                required
                value={email}
                onChange={handleEmailChange}
              />
              {emailError && ( 
                  <Form.Text className='text-danger'>
                    Email already exists <br/>
                  </Form.Text>
                  
                )}  
                 
                 <Form.Text className="text-muted ms-1">
                  <span>We care about your data in our <Link to="/privacy-policy" class="text-dark">privacy policy</Link>.</span>
                </Form.Text>

            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPhone">
              <Form.Control
                type="tel"
                placeholder="Phone Number"
                required
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicFullName">
              <Form.Control
                type="text"
                placeholder="Full Name"
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicUsername">
              <Form.Control
                type="text"
                placeholder="Username"
                required
                value={username}
                onChange={handleUsernameChange}
              />
              {usernameError && (
                <Form.Text className='text-danger'>
                  Username is not available <br/>
                </Form.Text>
              )}
            </Form.Group>
            <Button variant="" type="submit" className='bg-primary text-dark' disabled={usernameError||emailError}>
              Get Username
            </Button>
          </Form>
          {formSubmitted && <p className='text-success'>Username reserved successfully!</p>}
          {submitError && <p className='text-danger'>Error: {submitError}</p>}
        </Col>
      </Row>
    </Container>
  );
};

export default FormSection;
