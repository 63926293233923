import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import img1 from '../assets/court.png'
import img2 from '../assets/hand-shake.png'
import img3 from '../assets/gavel.png'
import img4 from '../assets/location.png'
import group from '../assets/groups.png'

const Network = () => {
  return (
    <>
    <Container className='network py-5'>
        <Row className='align-items-center'>
            <Col lg={10} md={12}>
                <h2>Connect and Network with all types of professionals in the property industry</h2>
                <p className='mt-4'>Expand Your Reach: Connect with Property Industry Leaders - Agents,<br/> Developers, Investors, & Deal Sourcers</p>
            </Col>
            <Col lg={8}  md={12}className="network-boxes mt-5">
                <Row>
                    <Col md={6} className="mb-4 pe-5">
                        <div>
                            <div className='d-flex'>
                                <img alt="card img" className="" src={img1} />
                            </div>
                            
                            <h3>Completely disrupting the property industry</h3>
                            <p>Say goodbye to auction time-wasting, legal pack purchases, and deal scouring—every listing is a deal!</p>
                        </div>
                    </Col>
                    <Col md={6} className="mb-4 pe-5">
                        <div>
                        <div className='d-flex'>
                            <img alt="card img" className="" src={img2} />
                        </div>
                        
                            <h3>Empowering Property Professionals</h3>
                            <p>Collaborate Seamlessly: Deal Sourcers and Estate Agents Unite on Our Platform</p>
                        </div>
                    </Col>
                    <Col md={6} className="mb-4 pe-5">
                        <div>
                        <div className='d-flex'>
                            <img alt="card img" className="" src={img3} />
                        </div>
                            <h3>Effortless Auction Pricing</h3>
                            <p>Unlock Deals Without the Bidding Stress</p>
                        </div>
                    </Col>
                    <Col md={6} className="mb-4 pe-5">
                        <div>
                            <div className='d-flex'>
                                <img alt="card img" className="" src={img4} />
                            </div>
                        
                            <h3>Property Hub: Where All Property Professionals Converge</h3>
                            <p>Connecting Developers, Investors, Landlords, Agents, and Deal Sourcers in One Unified Platform</p>
                        </div>
                    </Col>

                </Row>
            </Col>
            <Col md={12} lg={4}>
            <img alt="card img" className="img-fluid" src={group} />
            </Col>
        </Row>
        <Row className='py-5'>
            <Col md={12} className='py-5 text-center'>
                <h2>Where deals are made</h2>
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default Network