import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import footerImg from '../assets/footer.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube ,faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='py-5 mx-0 px-0  footer'>
    <Container className=' '>
        <Row>
            <Col  xl={3} lg={12} md={12} className="pe-5 mb-5 mt-4">
                <img src={footerImg} alt="d" className='img-fluid' />
                <h6>Where deals are made!</h6>
            </Col>
            <Col  xl={2} lg={4} md={4} className="mt-4">
                <h5>Learn More</h5>
                <ul className='ps-0'>
                    <li>About BelowTheMarket</li>
                    <li>Press Releases</li>
                    <li>Jobs</li>
                    <li><Link to="/privacy-policy" style={{ textDecoration: 'none' }} class="text-light">Privacy Policy</Link></li>
                    <li>Contact Us</li>
                </ul>
            </Col>
            <Col  xl={2} lg={4} md={4} className="mt-4">
                <h5>Pilot Program</h5>
                <ul className='ps-0'>
                    <li>Sign Up</li>
                    <li>About Pilot</li>
                </ul>
            </Col>
            <Col  xl={3} lg={4} md={4} className="mt-4">
                <h5>Learn More</h5>
                <ul className='ps-0'>
                    <li>Enquiries@belowthemarket.com</li>
                    <li>15345666</li>
                    <li>124 City Road, London, EC1V 2NX</li>
                </ul>
            </Col>
            <Col  xl={2} lg={12} md={12} className="mt-4">
                <h5>Socials</h5>
                <div className='d-flex justify-content-between w-75 mt-4'>
                <a href='https://www.facebook.com/belowthemarket'><FontAwesomeIcon icon={faFacebookF} className="icon fs-5 text-white" /></a>
                <a href='https://www.instagram.com/belowthemarket'><FontAwesomeIcon icon={faInstagram} className="icon fs-5 text-white" /></a>
                <a href='https://www.youtube.com/@BelowTheMarket'><FontAwesomeIcon icon={faYoutube} className="icon fs-5 text-white" /></a>
                <a href='https://www.linkedin.com/company/belowthemarket/'><FontAwesomeIcon icon={faLinkedinIn} className="icon fs-5 text-white" /></a>
                </div>
            </Col>
        </Row>
        <hr/>
        <div className='text-center'>
            <p> © 2024 BelowTheMarket | All Rights Reserved</p>
        </div>
    </Container>
    </div>
  )
}

export default Footer