import React from 'react';
import { Container } from 'react-bootstrap';
import '../App.css';

function PrivacyPolicy() {
  return (
    <Container className="privacy-policy my-5">
      <h1 className="display-4 mb-4">Privacy Policy</h1>
      <p className="text-muted mb-5">Last Updated: May 29, 2024</p>

      <section>
        <h2 className="h4 mb-3">Thank you for visiting our landing page.</h2>
        <p className="text-muted mb-4">
          We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines the types of information we collect, how we use and protect it, and your rights regarding your personal data.
        </p>
      </section>

      <section>
        <h2 className="h4 mb-3">1. Information We Collect</h2>
        <ul className="list-unstyled mb-4">
          <li><strong>Name:</strong> To personalize your experience and communications with us.</li>
          <li><strong>Email Address:</strong> To send you updates, promotional materials, and other information related to our services.</li>
          <li><strong>Phone Number:</strong> To provide you with timely information and support via calls or text messages.</li>
        </ul>
      </section>

      <section>
        <h2 className="h4 mb-3">2. How We Use Your Information</h2>
        <ul className="list-unstyled mb-4">
          <li><strong>Communication:</strong> To send you newsletters, updates, promotional materials, and provide support.</li>
          <li><strong>Personalization:</strong> To tailor our communications to your preferences and interests.</li>
          <li><strong>Improvement:</strong> To improve our landing page, services, and user experience.</li>
        </ul>
      </section>

      <section>
        <h2 className="h4 mb-3">3. Data Protection</h2>
        <p className="text-muted mb-4">
          We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. We use secure servers and encryption to safeguard your data.
        </p>
      </section>

      <section>
        <h2 className="h4 mb-3">4. Sharing Your Information</h2>
        <p className="text-muted mb-4">
          We do not sell, trade, or otherwise transfer your personal information to outside parties. However, we may share your information with trusted third parties who assist us in operating our landing page, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
        </p>
      </section>

      <section>
        <h2 className="h4 mb-3">5. Your Rights</h2>
        <ul className="list-unstyled mb-4">
          <li><strong>Access:</strong> You can request a copy of the personal information we hold about you.</li>
          <li><strong>Correction:</strong> You can request that we correct any inaccuracies in your personal information.</li>
          <li><strong>Deletion:</strong> You can request that we delete your personal information.</li>
          <li><strong>Unsubscribe:</strong> You can opt out of receiving our emails at any time by following the unsubscribe link in our communications.</li>
        </ul>
      </section>

      <section>
        <h2 className="h4 mb-3">6. Cookies and Tracking Technologies</h2>
        <p className="text-muted mb-4">
          Our landing page may use cookies and similar tracking technologies to enhance your experience and gather information about how you use our site. You can control cookie settings through your browser.
        </p>
      </section>

      <section>
        <h2 className="h4 mb-3">7. Changes to This Privacy Policy</h2>
        <p className="text-muted mb-4">
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our landing page. We encourage you to review this Privacy Policy periodically for any updates.
        </p>
      </section>

      <section>
        <h2 className="h4 mb-3">8. Contact Us</h2>
        <p className="text-muted mb-4">
          If you have any questions about this Privacy Policy or our data practices, please contact us at:
        </p>
        <address className="mb-4">
          <p>Below The Market Ltd</p>
          <p>124 City Road, London, EC1V 2NX</p>
          <a href="mailto:enquiries@belowthemarket.com" className="text-decoration-none">enquiries@belowthemarket.com</a>
        </address>
        <p className="text-muted">
          By using our landing page and providing your personal information, you consent to the terms of this Privacy Policy.
        </p>
      </section>
    </Container>
  );
}

export default PrivacyPolicy;
