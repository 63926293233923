import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import fees from '../assets/fees.png'

const Free = () => {
  return (
    <>
    <Container className='free bg-light'>
        <Row className="align-items-center px-4 py-5">
            <Col xl={6} lg={5} md={12} className=''>
                <img alt="card img" className="img-fluid" src={fees} />
            </Col>

            <Col xl={6} lg={7} md={12} className='p-4'>
                <h2>Cheapest fees in the UK</h2>
                <h5>Benefit from <span className='h4 fw-bold text-secondary'>FREE</span> use whilst in pilot stage</h5>
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default Free