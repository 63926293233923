import Header from '../components/Header';
import { Container } from 'react-bootstrap';
import Hero from '../components/Hero';
import FormSection from '../components/Form';
import Network from '../components/Network';
import Free from '../components/Free';
import SignUp from '../components/SignUp';
import Footer from '../components/Footer';

function Home() {
  return (
    <div className='p-0 m-0'>
      <Header />
      <Container>
        <Hero />
        <FormSection />
        <Network />
        <Free />
        <SignUp />
      </Container>
      <Footer />
    </div>
  );
}

export default Home;